<div class="lang">
	<button mat-icon-button [matMenuTriggerFor]="menu">
		<mat-icon>
			<img src="{{'./assets/images/'+selectedLang+'-flag.svg'}}" alt="">
		</mat-icon>
	</button>
	<mat-menu #menu="matMenu" class="lang-menu">
		<button mat-menu-item (click)="selectLang('en')">
			<mat-icon>
				<img src="./assets/images/en-flag.svg" alt="">
			</mat-icon>
		  <span>English</span>
		</button>
		<button mat-menu-item (click)="selectLang('es')">
			<mat-icon>
				<img src="./assets/images/es-flag.svg" alt="">
			</mat-icon>
		  <span>Español</span>
		</button>
	</mat-menu>
</div>