import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ICartDetails } from '../appModels/ICartDetails';
import { BackPromptComponent } from '../includes/popups/back-prompt/back-prompt.component';
import { CheckoutResolver } from '../pages/checkout/checkout.resolver';

@Injectable({
    providedIn: 'root'
})
export class CartStorage {

    tipAmount = 0;
    cart = new BehaviorSubject<ICartDetails[]>([]);
    coupon = new BehaviorSubject<any>({ couponCode: null, couponValue: 0 });
    couponValue: number = 0;
    couponCode: String;

    constructor(
        private checkoutResolver: CheckoutResolver,
        public dialog: MatDialog,
        private router: Router) {
        const cart_ = sessionStorage.getItem('cart');
        if (cart_) {
            this.cart.next(JSON.parse(cart_));
        }
        this.getTipAmount();
        this.getCoupon();
    }

    /**
     * add product in cart
     * @param cartDetail 
     */
    addProductInCart(cartDetail: ICartDetails) {

        const cartCopy = [...this.cart.value, cartDetail];
        this.cart.next(cartCopy);
        this.updateSessionStorage();
    }

    /**
     * remove product from cart
     * @param index 
     */
    removeProductFromCart(index) {
        const cartCopy = [...this.cart.value];
        cartCopy.splice(index, 1);
        this.cart.next(cartCopy);
        this.updateSessionStorage();
    }

    /**
     * update product in cart
     * @param index 
     * @param cartDetail 
     */
    updateProductInCart(index: number, cartDetail: ICartDetails) {
        const cartCopy = [...this.cart.value];
        cartCopy[index] = cartDetail;
        this.cart.next(cartCopy);
        this.updateSessionStorage();
    }

    /**
     * get cart details by index
     * @param index 
     */
    getCartDetailsByIndex(index) {
        return JSON.parse(JSON.stringify(this.cart.value[index]));
    }

    /**
     * clear cart
     */
    clearCart() {
        const dialogRef = this.dialog.open(BackPromptComponent, {
            maxWidth: '90%',
            minWidth: '90%',
            height: '90%',
            data: 'clearCart'
        });
        dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
            this.cart.next([]);
            this.clearSessionStorage();
            this.router.navigate(['/']);
        }
        });
    }
    /**
     * clear cart without dialog
     */
    public clearCartWithOutDialog() {
        this.cart.next([]);
        this.clearSessionStorage();
    }

    /**
     * update session storage
     */
    private updateSessionStorage() {
        sessionStorage.setItem('cart', JSON.stringify(this.cart.value));
    }

    /**
     * clear session storage
     */
    private clearSessionStorage() {
        sessionStorage.removeItem('cart');
    }


    // ######### helper methods

    /**
     * calculate per item price
     * @param item 
     */
    public calculatePerItemPrice(item): void {
        let price = item.productModel.basePrice;
        if (item.productModel.discount) {
            price = item.productModel.discount.discount;
        }
        item.cartDetailsVariantModelList.forEach(variant => {
            price = price + variant.price;
        });
        item.cartDetailsAddOnModelList.forEach(addOn => {
            price = price + addOn.addOnModel.addOnPrice;
        });
        price = price * item.quantity;
        item.price = price;
    }

    /**
     * get total price of cart
     * @param cartDetails 
     */
    public totalPriceOfCart(): number {
        let total = 0;
        if (this.cart.value) {
            this.cart.value.forEach(element => {
                total = total + element.price;
            });
            // this.cart.value.forEach(item => {
            //     total += item.price * item.quantity; // considering the quantity
            // });
        }
        return total;
    }

    /**
     * calculate total tax
     */
    public calculateTax(tax): number {
        return Number.parseFloat(
            (((this.totalPriceOfCart() + this.tipAmount) * tax) / 100).toFixed(2)
        );
        // return (this.totalPriceOfCart() + this.tipAmount) * tax / 100;
    }

    /**
     * calculate total
     */
    public calculateTotal(tax): number {
        // const tax1 = this.calculateTax(tax);
        // const totalPriceOfCart = this.totalPriceOfCart();
        // const total1 = +((totalPriceOfCart + this.tipAmount - this?.couponValue)* tax1 / 100).toFixed(2);
        // return total1;
        // return Number.parseFloat(
        //     (((this.totalPriceOfCart() + this.tipAmount - this?.couponValue) * tax) / 100).toFixed(2)
        // );

        return this.calculateTax(tax) + this.totalPriceOfCart() + this.tipAmount - this?.couponValue;


    }

    /**
   * get Tip Amount
   */
    getTipAmount(): void {
        this.checkoutResolver.tipAmount.subscribe(res => {
        this.tipAmount = res;
        });
    }

    /**
     * get coupon
     */
    getCoupon(): void {
        this.coupon.subscribe(res => {
          this.couponValue = res.couponValue;
          this.couponCode = res.couponCode;
        });
    }

}