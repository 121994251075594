import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: "kiosk-code",
    loadChildren: () => import('./pages/kiosk-code/kiosk-code.module').then(m => m.KioskCodeModule)
  },
  {
    path: "beeper",
    loadChildren: () => import('./pages/beepers/beepers.module').then(m => m.BeepersModule)
  },
  {
    path: "tables",
    loadChildren: () => import('./pages/table-mgmt/table-mgmt.module').then(m => m.TableMgmtModule)
  },
  {
    path: "menu",
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule)
  },
  {
    path: "thanks",
    loadChildren: () => import('./pages/thanks/thanks.module').then(m => m.ThanksModule)
  },
  {
    path: "checkout",
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: "product/:id",
    loadChildren: () => import('./pages/single-product/single-product.module').then(m => m.SingleProductModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
