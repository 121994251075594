

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NumberUtility {

    /**
     * get current epoc time
     * @returns 
     */
    public roundToTwo(num: number): number {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
}