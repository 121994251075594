<div class="order-type-container">
    <h1>Select Order Type</h1>
    <div class="options">
        <ng-container *ngFor="let orderType of orderTypes">
            <a class="bg-app outline-primary foreground" 
                *ngIf="orderType.active === true"
                (click)="onSelectType(orderType)">
                {{orderType.orderTypeName}}
            </a>
        </ng-container>
    </div>
    <!-- <div mat-dialog-actions>
        <button mat-stroked-button color="warn" [mat-dialog-close]="true">Save</button>
        <button mat-stroked-button class="light" (click)="onNoClick()">Close</button>
    </div> -->
</div>
