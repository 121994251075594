import { Component, Input } from '@angular/core';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';

@Component({
  selector: 'tastio-img',
  templateUrl: './tastio-img.component.html',
  styleUrls: ['./tastio-img.component.scss'],
})
export class TastioImgComponent {
  @Input() height: any;
  @Input() minHeight: any;
  @Input() width: any;
  @Input() src: string;
  theme:string;

  isLoading: boolean = false;

  constructor(private _du:DesignUtilityServices) {
    this.isLoading = true;
    this._du.theme.subscribe(res=>{
      this.theme = res;
    })
  }

  hideLoader() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }
}
