import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})

/* //// Usage Starts ////

onThemeDelete(uid){
  let data ={
    title:'Delete this Theme?',
    text:'Press "No Thanks" if you want to continue. Press "Yes" if you want to logout.'
  }

  this._du.openPrompt(data).subscribe(res=>{
    if(res){
      this.themeService.deleteThemeById(uid);
    }
  })
}
//// Usage Ends //// */


export class PromptComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PromptComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
