import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TenentContextService {

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    const tenantName = localStorage.getItem('tenant-name');
    if (tenantName) {
      return headers.append("tenant-name", tenantName);
    }
    return headers;
  }
}
