import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  selectedLang = new BehaviorSubject('en');
  constructor() { }
  
}
