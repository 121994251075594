import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestaurantInfoService } from '../appServices/restaurant-info.service';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class RestaurantBasicInfoStorage {
    restaurantBasicInfo = new BehaviorSubject(null);
    constructor(
        private restaurantInfoService: RestaurantInfoService,

    ) {

        this.getBasicInfo();
    }

    getBasicInfo() {
        if (!localStorage.getItem("basic-info")) {
            this.setRestaurantBasicInfoFromApi();
        } else {
            this.restaurantBasicInfo.next(JSON.parse(localStorage.getItem("basic-info")));

        }
    }

    setRestaurantBasicInfoFromApi() {
        this.restaurantInfoService.getBasicInfo().subscribe(res => {
            localStorage.setItem("basic-info", JSON.stringify(res));
            this.restaurantBasicInfo.next(res);
        })
    }

    clearInfo() {
        localStorage.removeItem("basic-info");
        this.restaurantBasicInfo.next(null);
    }
}