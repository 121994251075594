import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { PromptComponent } from '../includes/popups/prompt/prompt.component';
import { CustomSnackBar } from 'src/app/includes/utilities/custom-snack-bar/custom-snack-bar.service';
import { VoiceModelComponent } from '../includes/popups/voice-model/voice-model.component';


@Injectable({
  providedIn: 'root'
})
export class DesignUtilityServices {

  // New Subjects for Updated Header Concepts
  theme = new BehaviorSubject('dark-theme');  // For Header Color
  orderStep = new BehaviorSubject('');  // For Order Step
  menuScrollPos = new BehaviorSubject(null);  // For Order Step
  reVisitMenu = new BehaviorSubject(false);  // For eat Type
  internetStatus = new BehaviorSubject<string>('ONLINE');  // For eat Type
  selectedCamID = new BehaviorSubject<string>('');  // Selected Camera Id
  defaultCam = new BehaviorSubject<any>(null);  // For Default Camera
  degree = new BehaviorSubject<number>(0);  // For Camera Orientation

  validator = {
    mobile: '^((\\+91-?)|0)?[0-9]{10}$',
    email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$'
  }

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private customSnackBar: CustomSnackBar) { }



  //------------------------------------------// 
  // Voice Model                              //
  //------------------------------------------//
  openVoiceModel(promptData: any): Observable<any> {
    const dialogRef = this.dialog.open(VoiceModelComponent, {
      data: promptData,
      maxWidth: '85%',
      width: '500px'
    });

    return dialogRef.afterClosed()
  }

  //------------------------------------------// 
  // Custom Prompt                            //
  //------------------------------------------//
  openPrompt(promptData): Observable<any> {
    const dialogRef = this.dialog.open(PromptComponent, {
      data: promptData,
      maxWidth: '500px'
    });

    return dialogRef.afterClosed()
  }

  //------------------------------------------// 
  // Custom SnakeBar                          //
  //------------------------------------------//
  openSnackBar(message: string, action: string, duration: number, className: string): void {
    this.snackBar.open(message, action, {
      duration: duration * 1000,
      panelClass: [className]
    });
  }

  openCustomSnackBar(message: string, action: string, type: string) {
    if (type === 'success') {
      this.customSnackBar.successMessage(message, action);
    }
    if (type === 'info') {
      this.customSnackBar.infoMessage(message, action);
    }
    if (type === 'error') {
      this.customSnackBar.errorMessage(message, action);
    }
  }



}
