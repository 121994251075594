import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenentContextService } from './tenent-context.service';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TenentIdentifierInterceptor implements HttpInterceptor {
  constructor(private tenantService: TenentContextService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.tenantService.addTenantToHeaders(request.headers);

    request = request.clone({
      headers: headers
    });

    return next.handle(request)
      .pipe(
        // There may be other events besides the response.
        filter(event => event instanceof HttpResponse),
        tap((event: HttpResponse<any>) => {
        }));
  }
}