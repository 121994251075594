import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  api = environment.baseUrl;

  constructor(private httpClient: HttpClient) {
  }

  getStoreById(id: number) : Observable<any> {
    return this.httpClient.get(this.api + "/store/"+id );
  }


}
