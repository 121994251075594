import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuServices } from 'src/app/appServices/menu.service';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MenuResolver {
    selectedMenuIndex = new BehaviorSubject(1);
    searchItem = new BehaviorSubject<string>(null);
    store: any;

    clientType: string;
    menu: any[] = [];

    constructor(
        private _menuService: MenuServices,
        private _storeResolver: StoreResolver,
        private _kioskResolver: KioskResolver
    ) {
        this.subscribeStore();
        this.subscribeKioskDetails();
    }

    /**
     * subscribe kiosk details
     */
    subscribeKioskDetails() {
        this._kioskResolver.kioskDetails.subscribe(res => {
            if (res) {
                this.clientType = res.clientType;
            }
        })
    }

    subscribeStore() {
        this._storeResolver.store.subscribe(res => {
            this.store = res;
        });
    }
    

    /**
    * get menu
    */
    getMenu(forceGet:boolean = false): Observable<any> {
        if (!forceGet && this.menu.length > 0) {
            return of(this.menu);
        }
    
        return this._menuService.getMenu(this._storeResolver.getSelectedStore().menuId, this.clientType).pipe(
          map(res => this.menu = res)
        );
    }
    

     
}