import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  api = environment.baseUrl;

  activeOrder = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient) {

  }

  saveOrder(order): Observable<any> {
    return this.httpClient.post(this.api + "/order/kiosk/save", order);
  }

  getOrderTypes(storeId: number, type:string): Observable<any> {
    return this.httpClient.get(`${this.api}/store/${storeId}/orderTypes/${type}`);
  }

  makeCreditCardPayment(onliePaymentModel, gatewayName): Observable<any> {
    return this.httpClient.post(this.api + '/order/kiosk/card/' + gatewayName, onliePaymentModel);
  }

  /**
  * create order for cashier by order and payment intent
  * @param order
  * @param paymetIntent
  */
  createOrderByCardReader(paymetIntent, gateway, order): Observable<any> {
    return this.httpClient.post(this.api + '/order/kiosk/card-reader/' + gateway + '/' + paymetIntent, order);
  }


  /**
   * delete order by id
   * @param orderId 
   * @returns 
   */
  deleteOrder(orderId: number): Observable<any> {
    return this.httpClient.delete(this.api + "/order/" + orderId);
  }

  /**
   * get coupon codes by client type
   * @param currDate 
   * @returns 
   */
  getCouponCodesByClientType(currDate): Observable<any> {
    return this.httpClient.get(this.api + "/promo-code/client-type/" + "indoor-kiosk?currDate=" +currDate);
  }

  /**
   * get coupon by coupon code
   * @param storeId 
   * @param couponCode 
   * @returns 
   */
  getCouponByCouponCode(storeId: number, couponCode: string): Observable<any> {
    return this.httpClient.get(this.api + "/promo-code/indoor-kiosk/" + storeId + '/' +couponCode);
  }

}
