<div class="user-info-container" [ngClass]="{'portrait':portraitView}">
    <ng-container *ngIf="duplicateButtons">
        <ng-container *ngTemplateOutlet="btnRow; context: {position: 'top'}"></ng-container>
    </ng-container>
    <h1 mat-dialog-title class="flex-middle">
        {{'userInactivity.title' | translate}}
    </h1>
    <div mat-dialog-content>
        <div class="flex-middle">
            <app-countdown [running]="true"></app-countdown>
            <!-- <img src="assets/images/alarm.svg" /> -->
        </div>
    </div>
    <ng-container *ngTemplateOutlet="btnRow"></ng-container>
</div>


<ng-template #btnRow let-position="position">
    <div class="options" [class.top]="position==='top'">
        <a class="bg-app foreground" 
        (click)="onNoClick()">
            <figure>
                <img src="./assets/images/return.svg" alt="">
            </figure>
            {{'userInactivity.btn.return' | translate}}
        </a>
        <a class="bg-app foreground" 
        (click)="goToHome()">
        <figure>
            <img src="./assets/images/new-order.svg" alt="">
        </figure>
        {{'userInactivity.btn.newOrder' | translate}}
        </a>
    </div>
    <!-- <div mat-dialog-actions class="btn-row">
        <button mat-stroked-button color="primary" 
        (click)="onNoClick()">
            Return
        </button>
        <button mat-stroked-button class="ml-1" 
        (click)="goToHome()">
            New Order
        </button>
    </div> -->
</ng-template>