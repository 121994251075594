import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FeatureToggleService {
    findFeature(name:string){
        const tempObj = Features.find(v => v.name===name);
        return tempObj ? tempObj.active: false;
    }
}



export const Features:Feature[] = [
    {name: 'coupons', active: true},
    {name: 'camera', active: true},
    {name: 'theme', active: true},
    {name: 'audio', active: true},
]
  
export interface Feature{
    name:string;
    active:boolean;
}
