import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestaurantInfoService {
  api = environment.baseUrl + "/account-settings";

  constructor(private httpClient: HttpClient) {
  }

  getBasicInfo(): Observable<any> {
    return this.httpClient.get(this.api);
  }

}
