import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/appServices/helper.service';
import { LangService } from 'src/app/appServices/lang.service';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';

@Component({
  selector: 'app-back-prompt',
  templateUrl: './back-prompt.component.html',
  styleUrls: ['./back-prompt.component.scss']
})
export class BackPromptComponent implements OnInit {
  portraitView = false;
  basicInfo: any;
  duplicateButtons: boolean;

  constructor(
    public dialogRef: MatDialogRef<BackPromptComponent>,
    private _helperService: HelperService,
    @Inject(MAT_DIALOG_DATA) public data,
    private _langService: LangService,
    public translate: TranslateService,
    private kioskResolver: KioskResolver,
    private router:Router) {
    this._helperService.portraitView.subscribe(res =>{
      this.portraitView = res;
    });
    //------------------------------------------// 
    //  Ngx-Translator Code (Starts)            //
    //------------------------------------------//
    this._langService.selectedLang.subscribe(res => {
      translate.setDefaultLang(res);
    })
    translate.addLangs(['en', 'es']);
    //------------------------------------------// 
    //  Ngx-Translator Code (Ends)              //
    //------------------------------------------//
   }


   ngOnInit() {
    this.subscribeDuplicateButtons();
  }

  /**
   * Subscribe duplicate buttons
  */
   subscribeDuplicateButtons(){
    this.kioskResolver.duplicateButtons.subscribe(res=>{
      this.duplicateButtons = res;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goToHome() {
    this.onNoClick();
  }
  
}
