import { Component, Input, OnInit } from '@angular/core';
import { LangService } from 'src/app/appServices/lang.service';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit {
  selectedLang;
  constructor(private _langService:LangService) {
    this._langService.selectedLang.subscribe(res=>{
      this.selectedLang = res;
    });
  }

  ngOnInit(): void {
  }

  selectLang(lang){
    this._langService.selectedLang.next(lang);
  }

}
