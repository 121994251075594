import { Subscription, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { DesignUtilityServices } from '../appServices/design-utility.service';
import { KioskResolver } from './kiosk.resolver';
import { LangService } from '../appServices/lang.service';
import { CheckoutResolver } from '../pages/checkout/checkout.resolver';
import { MenuResolver } from '../pages/menu/menu.resolver';
import { CartStorage } from './cart.storage';
import { OrderService } from '../appServices/order.service';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class KioskSchedularResolver { 
    ActiveStatusSubscription: Subscription;

    constructor(
        private kioskResolver: KioskResolver,
        private _du: DesignUtilityServices,
        private _langService: LangService,
        private _menuResolver: MenuResolver,
        private cartStorage: CartStorage,
        private orderService: OrderService,
        private router: Router,
        private checkoutResolver: CheckoutResolver,
    ) { }


    /**
     * kiosk Active Schedular
     * @param kioskCode 
     */
   
    kioskActiveSchedular() {
        const checkKisokStatus = timer(20000, 20000);
        this.ActiveStatusSubscription = checkKisokStatus.subscribe(res =>{
            const kioskCode = localStorage.getItem('kiosk-code');
            if (kioskCode) {
                this.kioskResolver.getKioskDetailsByCode(Number.parseInt(kioskCode)).subscribe(res => {
                    this.kioskResolver.kioskStatus.next(res.active);
                    if(!res.active){
                        this.clearStorage();
                    }
                });
            }
        });
    }

    /**
     * Clear Storage
     */
    clearStorage() {
        this.orderService.activeOrder.next(null);
        this.cartStorage.clearCartWithOutDialog();
        this._menuResolver.selectedMenuIndex.next(null);
        this.checkoutResolver.beeperNumber.next('');
        this._du.menuScrollPos.next(0);
        this._langService.selectedLang.next('en');
        this.router.navigate(['/']);
        // this.ActiveStatusSubscription.unsubscribe();
    }

    


}