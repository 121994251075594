import { BehaviorSubject } from 'rxjs';
import { IOrderType } from '../appModels/IOrderType';
import { Injectable } from '@angular/core';
import { OrderService } from '../appServices/order.service';
import { KioskResolver } from './kiosk.resolver';

@Injectable({
    providedIn: 'root'
})
export class OrderTypeResolver {
    orderTypeList = new BehaviorSubject<IOrderType[]>(null);
    selectedOrderType = new BehaviorSubject<IOrderType>(null);

    constructor(
        private orderService: OrderService,
        private _kioskResolver: KioskResolver) { }
    /**
     * Initialize Order Types
     * @param orderTypes 
     */
    initializeOrderTypes(orderTypes: IOrderType[]) {
        this.orderTypeList.next(orderTypes);
    }

    /**
     * get order types from api
     * @param storeId 
     */
    getOrderTypes(storeId: number) {
        this._kioskResolver.kioskDetails.subscribe(res=>{
            if(res){
                this.orderService.getOrderTypes(storeId, res.clientType).subscribe(res => {
                    res && this.initializeOrderTypes(res);
                });
            }
        })
    }

    /**
     * clear order Types
     */
    clearOrderTypes() {
        this.orderTypeList.next(null);
        this.selectedOrderType.next(null);
    }
}