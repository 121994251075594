import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-visual-cue',
  templateUrl: './visual-cue.component.html',
  styleUrls: ['./visual-cue.component.scss']
})

export class VisualCueComponent implements OnInit {

  @Input() data:string;
  @Input() open:boolean = true;
  @Output() onModelChanged = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
  }
  
  onClose(): void {
    this.open = false;
    this.onModelChanged.emit('close');
  }
  
  // onNext(){
  //   this.onModelChanged.emit('next');
  // }
}
