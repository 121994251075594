import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OrderService } from 'src/app/appServices/order.service';
import { PaymentGatewayService } from 'src/app/appServices/payment-gateway.service';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { NumberUtility } from 'src/app/appUtilities/number.utility';

@Injectable({
  providedIn: 'root'
})
export class CheckoutResolver {
  subTotalAmount = new BehaviorSubject(0);
  tipAmount = new BehaviorSubject(0);
  tipToggleVal = new BehaviorSubject<any>(null);
  paymentMethods = new BehaviorSubject<any[]>(null);
  beeperNumber = new BehaviorSubject<string>('');
  platformFee = 0;
  
  constructor(
    public dialog: MatDialog,
    private paymentGatewayService: PaymentGatewayService,
    private orderService: OrderService,
    private _kioskResolver: KioskResolver,
    private router:Router,
    private _storeResolver: StoreResolver,
    private numberUtility: NumberUtility
  ) {

  }
 
 
  /**
   * get Payment Methods
   */
   getPaymentMethods() {
    this._kioskResolver.kioskDetails.subscribe(res=>{
      if(res){
        const storeId = this._storeResolver.getSelectedStore().id;
        this.paymentGatewayService.getAllPaymentGateways(storeId, res.clientType).subscribe(res => {
          res && this.paymentMethods.next(res);
        });
      }
  })
    
  }

  /**
   * place cash order
   */
   cashPayment(orderPayload:any) {
    this.orderService.saveOrder(orderPayload).subscribe(res => {
      this.orderService.activeOrder.next(res);
      this.router.navigate(['thanks']);
    })
  }


  /**
   * get platformfee from rules
   * @param totalAmount 
   * @param platformFeeRules 
   * @returns 
   */
  getPlatformFeeFromRules(totalAmount: number, platformFeeRules: any[],  defaultPlatformFee: number) {
    let result = 0;
    for (let i = 0; i < platformFeeRules.length; i++) {
      const min = platformFeeRules[i].minOrderAmount;
      const max = platformFeeRules[i].maxOrderAmount;
      if (totalAmount > min && totalAmount <= max) {
        result = platformFeeRules[i].fee;
        break;
      }
    }
    return result > 0 ? result : defaultPlatformFee;
  }


  /**
   * Calculate Service Fee
   * @param totalAmount 
   * @param paymentMode 
   * @param serviceFeeModel 
   * @returns 
   */
  calculateServiceFee(totalAmount: number, paymentMode: string, serviceFeeModel:any): number {
    if (paymentMode === "cardReader" && serviceFeeModel) {
      const fixed_fee = serviceFeeModel?.inPersonFixedFee;
      const percent_fee = serviceFeeModel?.inPersonFeePercentageFee * 0.01;
      this.platformFee =this.getPlatformFeeFromRules(totalAmount, serviceFeeModel?.platformFeeRules, serviceFeeModel?.platformFee);
      const amountToPay = ((totalAmount + this.platformFee) + fixed_fee) / (1 - percent_fee);
      return this.numberUtility.roundToTwo(amountToPay - totalAmount);
    }
    else if (paymentMode === "card" && serviceFeeModel) {
      const fixed_fee = serviceFeeModel?.fixedFee;
      const percent_fee = serviceFeeModel?.percentageFee * 0.01;
      this.platformFee =this.getPlatformFeeFromRules(totalAmount, serviceFeeModel?.platformFeeRules, serviceFeeModel?.platformFee);
      const amountToPay = ((totalAmount + this.platformFee) + fixed_fee) / (1 - percent_fee);
      return this.numberUtility.roundToTwo(amountToPay - totalAmount);
    }
    else {
      return 0;
    }
  }
  
}  