<div class="card-reader-info-container">
    <h1 mat-dialog-title>Card Reader Number</h1>
    <div mat-dialog-content>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Card Reader Number</mat-label>
            <input matInput type="text" [(ngModel)]="cardReaderNumber" (ngModelChange)="cardReaderNumber = cardReaderNumber.trim()">
            <button mat-button *ngIf="cardReaderNumber" matSuffix mat-icon-button aria-label="Clear" (click)="cardReaderNumber=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button [mat-dialog-close]="true" [disabled]="!cardReaderNumber || cardReaderNumber?.length === 0" cdkFocusInitial color="primary" (click)="saveCardReaderInfoToCookie()">Save</button>
    </div>
</div>