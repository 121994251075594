import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare const annyang: any;

@Component({
  selector: 'app-voice-model',
  templateUrl: './voice-model.component.html',
  styleUrls: ['./voice-model.component.scss']
})

export class VoiceModelComponent implements OnInit {

  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any;
  
  constructor(
    public dialogRef: MatDialogRef<VoiceModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private ngZone: NgZone) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.startVoiceRecognition();
  }

	initializeVoiceRecognitionCallback(): void {
		annyang.addCallback('error', (err:any) => {
			if(err.error === 'network'){
			this.voiceText = "Internet is require";
			annyang.abort();
			this.ngZone.run(() => this.voiceActiveSectionSuccess = true);
			} else if (this.voiceText === undefined) {
				this.ngZone.run(() => this.voiceActiveSectionError = true);
				annyang.abort();
			}
		});

		annyang.addCallback('soundstart', (res:any) => {
			this.ngZone.run(() => this.voiceActiveSectionListening = true);
		});

		annyang.addCallback('end', () => {
      if (this.voiceText === undefined) {
        this.ngZone.run(() => this.voiceActiveSectionError = true);
				annyang.abort();
			}
		});

		annyang.addCallback('result', (userSaid:any) => {
			this.ngZone.run(() => this.voiceActiveSectionError = false);
			let queryText: any = userSaid[0];
			annyang.abort();
      this.voiceText = queryText;
			this.ngZone.run(() => this.voiceActiveSectionListening = false);
      this.ngZone.run(() => this.voiceActiveSectionSuccess = true);
		});
	}

	startVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = false;
		this.voiceActiveSectionError = false;
		this.voiceActiveSectionSuccess = false;
    this.voiceText = undefined;

		if (annyang) {
			let commands = {
				'demo-annyang': () => { }
			};
			annyang.addCommands(commands);
        this.initializeVoiceRecognitionCallback();
			  annyang.start({ autoRestart: false });
		}
	}

	closeVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = true;
    this.voiceActiveSectionError = false;
    this.voiceActiveSectionSuccess = false;
    this.voiceActiveSectionListening = false;
    this.voiceText = undefined;

    if(annyang){
      annyang.abort();
    }
	}
  onMicClick(){
    if(!this.voiceActiveSectionListening){
      this.startVoiceRecognition();
    }
  }

}
