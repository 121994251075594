<div class="img" [class.light]="theme==='light-theme'">
  <div
    [ngStyle]="{ height: height + 'px', width: width + 'px' }"
    *ngIf="isLoading"
    class="loading foreground-3"
  >
    <!-- Image is Loading... -->
  </div>
  <img
    [src]="src"
    [ngStyle]="{
      visibility: isLoading ? 'hidden' : '',
      height: height ? height : '',
      'min-height': minHeight ? minHeight : '',
      width: width ? width : ''
    }"
    (load)="hideLoader()"
    (error)="hideLoader()"
  />
</div>
