<div class="dialog-wrapper">
    <h1>{{data.title}}</h1>
    <a mat-icon-button (click)="onNoClick()" class="close">
        <mat-icon class="material-icons-outlined">close</mat-icon>
    </a>
    
    <!-- voiceActiveSectionListening: {{voiceActiveSectionListening}}
    <br>
    <br>
    voiceActiveSectionDisabled: {{voiceActiveSectionDisabled}} -->
    <div class="dialog-content">
        <ng-template #voicesection>
            <ng-container *ngIf="!voiceActiveSectionError; else failure">
              <ng-container *ngIf="!voiceText">
                <p class="voice-text">We are listening...</p>
              </ng-container>
              <ng-container *ngIf="voiceText">
                <p class="voice-text">{{voiceText}}</p>
                <div class="use-this">
                    <button mat-flat-button
                        color="primary" 
                        [mat-dialog-close]="voiceText">
                        <mat-icon>done</mat-icon>
                        Grab this text
                    </button>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #failure>
              <p class="voice-text">Didn't catch that</p>
            </ng-template>
            <!-- <div>
              <button (click)="closeVoiceRecognition()">Close</button>
              <button (click)="startVoiceRecognition()">Restart</button>
            </div> -->
        </ng-template>

        <div *ngIf="voiceActiveSectionDisabled; else voicesection;">
            <button type="button" (click)="startVoiceRecognition()">Record</button>
        </div>
        
        <!-- <div class="mic listening stopped"> -->
        <div class="mic" [ngClass]="{
            'stopped' : !voiceActiveSectionListening,
            'listening' : voiceActiveSectionListening
        }" (click)="onMicClick()">
            <div class="circle background" *ngIf="voiceActiveSectionListening"></div>
            <div class="gn">
                <div class="mc"></div>
            </div>
            <span class="txt" *ngIf="!voiceActiveSectionListening">
                Tap the microphone to try again
            </span>
        </div>


        
          
    </div>
    <!-- <div class="dialog-footer">
        <button mat-stroked-button class="btn" color="primary" type="submit" >Submit</button>
        <button mat-stroked-button (click)="onNoClick()">No Thanks</button>
    </div> -->
    
    <!-- <div mat-dialog-actions>
        <button mat-stroked-button color="warn" [mat-dialog-close]="true">Ok</button>
        <button mat-stroked-button class="light" (click)="onNoClick()">Close</button>
    </div> -->
</div>
 