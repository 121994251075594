import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { OrderService } from 'src/app/appServices/order.service';
import { PaymentGatewayService } from 'src/app/appServices/payment-gateway.service';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { NumberUtility } from 'src/app/appUtilities/number.utility';

@Injectable({
  providedIn: 'root'
})
export class CheckoutResolver {
  subTotalAmount = new BehaviorSubject(0);
  tipAmount = new BehaviorSubject(0);
  tipToggleVal = new BehaviorSubject<any>(null);
  paymentMethods = new BehaviorSubject<any[]>(null);
  beeperNumber = new BehaviorSubject<string>('');
  platformFee = 0;
  instantDepositConfig: any;
  
  constructor(
    public dialog: MatDialog,
    private paymentGatewayService: PaymentGatewayService,
    private orderService: OrderService,
    private _kioskResolver: KioskResolver,
    private router:Router,
    private _storeResolver: StoreResolver,
    private numberUtility: NumberUtility,
    private cashMgmtService: CashMgmtService
  ) {
    this.getInstantDepositConfig();

  }
 
  /**
   * Get instant deposit config
   */
  getInstantDepositConfig(){
    this.cashMgmtService.getConfiguration('instantDeposit').subscribe(res=>{
        this.instantDepositConfig = res.value;
    })
  }
 
  /**
   * get Payment Methods
   */
   getPaymentMethods() {
    this._kioskResolver.kioskDetails.subscribe(res=>{
      if(res){
        const storeId = this._storeResolver.getSelectedStore().id;
        this.paymentGatewayService.getAllPaymentGateways(storeId, res.clientType).subscribe(res => {
          res && this.paymentMethods.next(res);
        });
      }
  })
    
  }

  /**
   * place cash order
   */
   cashPayment(orderPayload:any) {
    this.orderService.saveOrder(orderPayload).subscribe(res => {
      this.orderService.activeOrder.next(res);
      this.router.navigate(['thanks']);
    })
  }


  /**
   * get platformfee from rules
   * @param totalAmount 
   * @param platformFeeRules 
   * @returns 
   */
  getPlatformFeeFromRules(totalAmount: number, platformFeeRules: any[],  defaultPlatformFee: number) {
    let result = 0;
    for (let i = 0; i < platformFeeRules.length; i++) {
      const min = platformFeeRules[i].minOrderAmount;
      const max = platformFeeRules[i].maxOrderAmount;
      if (totalAmount > min && totalAmount <= max) {
        result = platformFeeRules[i].fee;
        break;
      }
    }
    return result > 0 ? result : defaultPlatformFee;
  }


  
  // calculateServiceFee(totalAmount: number, paymentMode: string, serviceFeeModel:any): number {
  //   if (paymentMode === "cardReader" && serviceFeeModel) {
  //     const fixed_fee = serviceFeeModel?.inPersonFixedFee;
  //     const percent_fee = serviceFeeModel?.inPersonFeePercentageFee * 0.01;
  //     this.platformFee =this.getPlatformFeeFromRules(totalAmount, serviceFeeModel?.platformFeeRules, serviceFeeModel?.platformFee);
  //     const amountToPay = ((totalAmount + this.platformFee) + fixed_fee) / (1 - percent_fee);
  //     return this.numberUtility.roundToTwo(amountToPay - totalAmount);
  //   }
  //   else if (paymentMode === "card" && serviceFeeModel) {
  //     const fixed_fee = serviceFeeModel?.fixedFee;
  //     const percent_fee = serviceFeeModel?.percentageFee * 0.01;
  //     this.platformFee =this.getPlatformFeeFromRules(totalAmount, serviceFeeModel?.platformFeeRules, serviceFeeModel?.platformFee);
  //     const amountToPay = ((totalAmount + this.platformFee) + fixed_fee) / (1 - percent_fee);
  //     return this.numberUtility.roundToTwo(amountToPay - totalAmount);
  //   }
  //   else {
  //     return 0;
  //   }
  // }

  /**
   * Calculate Service Fee
   * @param totalAmount 
   * @param paymentMode 
   * @param serviceFeeModel 
   * @returns 
   */
  calculateStripeProcessingFee(
    totalAmount: number,
    paymentMethod: any,
    stripeProcessingFees: any
  ): number {
    let processingFee = 0;
    let fixed_fee = 0;
    let percent_fee = 0;
    let amountToPay = 0;

    if (paymentMethod === 'card') {
      fixed_fee = stripeProcessingFees?.fixedFee;
      percent_fee = stripeProcessingFees?.percentageFee * 0.01;
    }

    if (paymentMethod === 'cardReader') {
      fixed_fee = stripeProcessingFees?.inPersonFixedFee;
      percent_fee = stripeProcessingFees?.inPersonFeePercentageFee * 0.01;
    }

    const platformFee = this.getPlatformFeeFromRules(
      totalAmount,
      stripeProcessingFees?.platformFeeRules,
      stripeProcessingFees?.platformFee
    );

    if (this.instantDepositConfig === '1') {
      percent_fee = percent_fee + (0.01);
    }

    if (paymentMethod === 'cardReader') {
      amountToPay = (totalAmount + fixed_fee) / (1 - percent_fee);
      // amountToPay = (totalAmount + platformFee + fixed_fee) / (1 - percent_fee);
    } else {
      const amountToRestaurant = (totalAmount + fixed_fee) / (1 - percent_fee);
      // const amountToTastio = (platformFee + fixed_fee) / (1 - percent_fee);  
      // amountToPay = amountToRestaurant  + amountToTastio;
      amountToPay = amountToRestaurant;
    }

    processingFee = this.numberUtility.roundToTwo(amountToPay - totalAmount);

    return processingFee;
  }
  
}  