import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IOrderType } from 'src/app/appModels/IOrderType';
import { OrderTypeResolver } from 'src/app/appStorage/order-type.resolver';

@Component({
  selector: 'app-order-type-dialog',
  templateUrl: './order-type-dialog.component.html',
  styleUrls: ['./order-type-dialog.component.scss']
})
export class OrderTypeDialogComponent implements OnInit {

  
  orderTypes: IOrderType[];
  constructor(
    public dialogRef: MatDialogRef<OrderTypeDialogComponent>,
    private orderTypeResolver: OrderTypeResolver
    ) {}
    
  onNoClick(): void {
    this.dialogRef.close();
  }
    
  ngOnInit(): void {
    this.subscribeOrderTypes();
  }


  /**
   * subscribe order types
   */
   subscribeOrderTypes() {
    this.orderTypeResolver.orderTypeList.subscribe(res => {
       this.orderTypes = res;
    });
  }

  onSelectType(orderType: IOrderType) {
    this.orderTypeResolver.selectedOrderType.next(orderType);
    this.dialogRef.close();
  }

}
