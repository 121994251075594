import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CashMgmtService {
  api = environment.baseUrl+'/';

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get configuration
   * @returns 
   */
  getConfiguration(type:string): Observable<any> {
    return this.httpClient.get(`${this.api}configuration/getByParameter/${type}`);
  }

}
