import { Component, Input, OnInit } from '@angular/core';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.css']
})
export class BackComponent implements OnInit {
  color = '#ffffff';
  constructor(private _du:DesignUtilityServices) {
    this._du.theme.subscribe(res =>{
      if(res==='dark-theme'){
        this.color = '#ffffff';
      }else{
        this.color = '#454545';
      }
    })
  }

  ngOnInit(): void {
  }

}
