import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { HelperService } from 'src/app/appServices/helper.service';
import { LangService } from 'src/app/appServices/lang.service';
import { OrderService } from 'src/app/appServices/order.service';
import { CartStorage } from 'src/app/appStorage/cart.storage';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';

@Component({
  selector: 'app-user-inactivity-model',
  templateUrl: './user-inactivity-model.component.html',
  styleUrls: ['./user-inactivity-model.component.scss']
})
export class UserInactivityModelComponent implements OnInit {
  portraitView = false;
  duplicateButtons: boolean;

  constructor(
    public dialogRef: MatDialogRef<UserInactivityModelComponent>,
    private router: Router,
    private _du: DesignUtilityServices,
    private cartStorage: CartStorage,
    private _helperService: HelperService,
    private orderService: OrderService,
    private _langService: LangService,
    public translate: TranslateService,
    private kioskResolver: KioskResolver
  ) {
    this._helperService.portraitView.subscribe(res =>{
      this.portraitView = res;
    });
    //------------------------------------------// 
    //  Ngx-Translator Code (Starts)            //
    //------------------------------------------//
    this._langService.selectedLang.subscribe(res => {
      translate.setDefaultLang(res);
    })
    translate.addLangs(['en', 'es']);
    //------------------------------------------// 
    //  Ngx-Translator Code (Ends)              //
    //------------------------------------------//
   }


  ngOnInit() {
    this.subscribeDuplicateButtons();
  }

  /**
   * Subscribe duplicate buttons
  */
   subscribeDuplicateButtons(){
    this.kioskResolver.duplicateButtons.subscribe(res=>{
      this.duplicateButtons = res;
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  goToHome() {
    this.onNoClick();
    this.cartStorage.clearCart();
  }
}
