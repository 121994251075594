<div class="countdown">
    <h2>{{'countdown.p' | translate}}</h2>
    <div class="spinner">
        <mat-progress-spinner
            color="primary"
            mode="determinate"
            [value]="percentage"
            diameter = "150">
        </mat-progress-spinner>
        <span class="count">{{count}}</span>
        <span class="seconds">{{'countdown.seconds' | translate}}</span>
    </div>
</div>
 