import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { LangService } from 'src/app/appServices/lang.service';
import { OrderService } from 'src/app/appServices/order.service';
import { CartStorage } from 'src/app/appStorage/cart.storage';


@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnChanges, OnDestroy {

  @Input() running:boolean;

  totalCount:number =15;
  count:number = this.totalCount;
  remainingCount:number = 15;
  totalPercentage:number = 100; // total percentage
  percentage:number = 100; // percentage
  countSubscription: Subscription;
  
  countDown = timer(0, 1000);
  
  constructor(private router: Router,
    private orderService: OrderService,
    private cartStorage: CartStorage,
    private _du:DesignUtilityServices,
    private _langService:LangService,
    public translate: TranslateService,
    public dialog: MatDialog) { 
      //------------------------------------------// 
      //  Ngx-Translator Code (Starts)            //
      //------------------------------------------//
      this._langService.selectedLang.subscribe(res => {
        translate.setDefaultLang(res);
      })
      translate.addLangs(['en', 'es']);
      //------------------------------------------// 
      //  Ngx-Translator Code (Ends)              //
      //------------------------------------------//
    }

  ngOnInit(): void {
    // this.start();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.running.currentValue){
      this.start();
    }else{
      this.pause();
    }
  }
  
  start(){
    if(this.count < this.totalCount){
      this.remainingCount = this.count;
    }
    if(this.percentage < 100){
      this.totalPercentage = this.percentage;
    }
    this.countSubscription = this.countDown.subscribe(res =>{
      this.count = this.remainingCount - res;
      this.percentage =  this.totalPercentage - (res*100/this.totalCount);
      
      if(this.count ===0){
        this.countSubscription.unsubscribe();
        // this.clearStorage();
        this.router.navigate(['/']);
        this.dialog.closeAll()
      }
    })
  }
  
  pause() {
    this.countSubscription.unsubscribe();
  }

  clearStorage() {
    this.cartStorage.clearCart();
    this.orderService.activeOrder.next(null);
    this._du.menuScrollPos.next(0);
    this._langService.selectedLang.next('en');
    this.router.navigate(['/']);
  }
  ngOnDestroy(){
    this.countSubscription.unsubscribe();
  }
}
