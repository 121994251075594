import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StoresService } from '../appServices/stores.service';


@Injectable({
    providedIn: 'root'
})
export class StoreResolver {

    store = new BehaviorSubject<any>(null);


    constructor(
        private storesService: StoresService
    ) { }


    /**
     * get store details by id
     * @param storeId  
     */
    getStore(storeId: number) {
        this.storesService.getStoreById(storeId).subscribe(res => {
            this.store.next(res);
            this.setSelectedStore(res);
        });
    }

    /**
     * set selected store
     * @param store 
     */
    public setSelectedStore(store: any) {
        localStorage.setItem("store", JSON.stringify(store));
    }


    /**
     * get selected store
     * @returns 
     */
    public getSelectedStore(): any {
        return JSON.parse(localStorage.getItem("store"));
    }

}