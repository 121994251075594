import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-reader-info',
  templateUrl: './card-reader-info.component.html',
  styleUrls: ['./card-reader-info.component.scss']
})
export class CardReaderInfoComponent implements OnInit {
  cardReaderNumber: string;
  constructor(
  ) { }

  ngOnInit() {
    this.getCardReaderInfoFromCookie();
  }

  /**
   * save card reader info
   */
  saveCardReaderInfoToCookie() {
    localStorage.setItem('card-reader-number', this.cardReaderNumber);
  }

  getCardReaderInfoFromCookie() {
    this.cardReaderNumber = localStorage.getItem('card-reader-number');
  }


}
