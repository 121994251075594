import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LangService } from './lang.service';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  selectedLang;
  playSecond = new BehaviorSubject(false);
  enableAudio = new BehaviorSubject(false);
  constructor(private _langService:LangService) { 
    this._langService.selectedLang.subscribe(res=>{
      this.selectedLang = res;
    })
  }

  audioStream = [
    { 
      name: 'buttonClick',
      audio : new Audio("/assets/audio/button-click.mp3")
    },
    { 
      name: 'tapToStart',
      audio : new Audio("/assets/audio/en/tap-to-start.wav")
    },
    { 
      name: 'clickOnProduct',
      audio : new Audio("/assets/audio/en/click-on-product.wav")
    },
    { 
      name: 'product1',
      audio : new Audio("/assets/audio/en/product_1.wav")
    },
    { 
      name: 'product2',
      audio : new Audio("/assets/audio/en/product_2.wav")
    },
    { 
      name: 'product3',
      audio : new Audio("/assets/audio/en/product_3.wav")
    },
    { 
      name: 'stillLooking',
      audio : new Audio("/assets/audio/en/Still_looking.wav")
    },
    { 
      name: 'addToCart',
      audio : new Audio("/assets/audio/en/add-to-cart.wav")
    },
    { 
      name: 'checkout1',
      audio : new Audio("/assets/audio/en/checkout_1.wav")
    },
    { 
      name: 'checkout2',
      audio : new Audio("/assets/audio/en/checkout_2.wav")
    },
    { 
      name: 'checkout3',
      audio : new Audio("/assets/audio/en/checkout_3.wav")
    },
    // { 
    //   name: 'checkout4',
    //   audio : new Audio("/assets/audio/en/checkout_4.wav")
    // },
    { 
      name: 'payWithCardOrAtWindow',
      audio : new Audio("/assets/audio/en/pay_with_card_or_at_window.wav")
    },
    { 
      name: 'pleasePullForward',
      audio : new Audio("/assets/audio/en/Please_pull_forward.wav")
    },
    { 
      name: 'eatHereOrToGo',
      audio : new Audio("/assets/audio/en/eatHereOrToGo.wav")
    },
    { 
      name: 'receipt_email_or_text',
      audio : new Audio("/assets/audio/en/receipt_email_or_text.wav")
    },
  ];

  audioStreamES = [
    { 
      name: 'buttonClick',
      audio : new Audio("/assets/audio/button-click.mp3")
    },
    { 
      name: 'tapToStart',
      audio : new Audio("/assets/audio/es/tap-to-start.wav")
    },
    { 
      name: 'clickOnProduct',
      audio : new Audio("/assets/audio/es/click-on-product.wav")
    },
    { 
      name: 'product1',
      audio : new Audio("/assets/audio/es/product_1.wav")
    },
    { 
      name: 'product2',
      audio : new Audio("/assets/audio/es/product_2.wav")
    },
    { 
      name: 'product3',
      audio : new Audio("/assets/audio/es/product_3.wav")
    },
    { 
      name: 'stillLooking',
      audio : new Audio("/assets/audio/es/Still_looking.wav")
    },
    { 
      name: 'addToCart',
      audio : new Audio("/assets/audio/es/add-to-cart.wav")
    },
    { 
      name: 'checkout1',
      audio : new Audio("/assets/audio/es/checkout_1.wav")
    },
    { 
      name: 'checkout2',
      audio : new Audio("/assets/audio/es/checkout_2.wav")
    },
    { 
      name: 'checkout3',
      audio : new Audio("/assets/audio/es/checkout_3.wav")
    },
    // { 
    //   name: 'checkout4',
    //   audio : new Audio("/assets/audio/es/checkout_4.wav")
    // },
    { 
      name: 'payWithCardOrAtWindow',
      audio : new Audio("/assets/audio/es/pay_with_card_or_at_window.wav")
    },
    { 
      name: 'pleasePullForward',
      audio : new Audio("/assets/audio/es/Please_pull_forward.wav")
    },
    { 
      name: 'eatHereOrToGo',
      audio : new Audio("/assets/audio/es/eatHereOrToGo.wav")
    },
    { 
      name: 'receipt_email_or_text',
      audio : new Audio("/assets/audio/es/receipt_email_or_text.wav")
    },
  ];

  playAudio(audioName){
    this.stopAll();
    if(this.enableAudio.value){
      let audio;
      if(this.selectedLang == 'en'){
        audio = this.audioStream.find(n => n.name == audioName).audio;
      }else{
        audio = this.audioStreamES.find(n => n.name == audioName).audio;
      }
      audio.load();
      audio.play();
      return audio.addEventListener;
    };
    // audio.addEventListener("ended", ()=>{
    //   alert(0)
    // })
  }

  stopAll(){
    this.playSecond.next(false);
    this.audioStream.map(a =>{
      a.audio.pause();
    });
    this.audioStreamES.map(a =>{
      a.audio.pause();
    });
  }
  
}
