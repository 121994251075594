<div class="user-info-container" [ngClass]="{
    'portrait':portraitView,
    'hasDuplicateBtns' : duplicateButtons
}">
    <div class="row">
        <div class="col-left">
            <ng-container *ngIf="duplicateButtons">
                <ng-container *ngTemplateOutlet="btnRow; context: {position: 'top'}"></ng-container>
            </ng-container>
            <h1 mat-dialog-title class="flex-middle">
                <ng-container *ngIf="data==='back'">{{'backPrompt.title' | translate}}</ng-container>
            <ng-container *ngIf="data==='clearCart'">{{'backPrompt.titleClearCart' | translate}}</ng-container>
            </h1>
            <ng-container *ngIf="portraitView">
                <div mat-dialog-content>
                    <ng-container *ngTemplateOutlet="proList"></ng-container>
                    
                    <ng-container *ngTemplateOutlet="summary"></ng-container>
                </div>
            </ng-container>
            <ng-container *ngTemplateOutlet="btnRow"></ng-container>
        </div>
    </div>
    
</div>


<ng-template #btnRow let-position="position">
    <div class="options" [class.top]="position==='top'">
        <a class="bg-app foreground" 
        (click)="onNoClick()">
            <figure>
                <img src="./assets/images/mark.svg" alt="">
            </figure>
            {{'backPrompt.btn.discard' | translate}}
        </a>
        <a class="bg-app foreground" 
        [mat-dialog-close]="'confirm'">
        <figure>
            <img src="./assets/images/tick.svg" alt="">
        </figure>
        {{'backPrompt.btn.confirm' | translate}}
        </a>
    </div>
</ng-template>
 