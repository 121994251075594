import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuServices } from 'src/app/appServices/menu.service';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class MenuResolver {
    selectedMenuIndex = new BehaviorSubject(1);
    searchItem = new BehaviorSubject<string>(null);
    store: any;

    clientType: string;
    menu: any[] = [];

    constructor(
        private _menuService: MenuServices,
        private _storeResolver: StoreResolver,
        private _kioskResolver: KioskResolver
    ) {
        this.subscribeStore();
        this.subscribeKioskDetails();
    }

    /**
     * subscribe kiosk details
     */
    subscribeKioskDetails() {
        this._kioskResolver.kioskDetails.subscribe(res => {
            if (res) {
                this.clientType = res.clientType;
            }
        })
    }

    subscribeStore() {
        this._storeResolver.store.subscribe(res => {
            this.store = res;
        });
    }
    

    /**
    * get menu
    */
    getMenu(forceGet:boolean = false): Observable<any> {
        if (!forceGet && this.menu.length > 0) {
            return of(this.menu);
        }
    
        return this._menuService.getMenu(this._storeResolver.getSelectedStore().menuId, this.clientType).pipe(
          map(res => 
            this.menu = res
            .map((category: any) => {
                if (this.isProductAvailableNow(category)) {
                  category.products = category.products.filter((product: any) => 
                    this.isProductAvailableNow(product)
                  );
                  return category; 
                }
                return null;
              })
              .filter((category: any | null): category is any => category !== null)
            )
        );
    }
    


    isProductAvailableNow(item: any): boolean {
        const currentDay = moment().format('dddd'); // Get current day as string like "Sunday", "Monday", etc.
        const currentTime = moment(); // Get the current time as moment object
    
        // Find today's working day model in item.workingDayModels
        const todayWorkingDay = item.workingDayModels.find(
          (dayModel: any) => dayModel.day === currentDay
        );
    
        const hasWorkingHours = item.workingDayModels.some(
          (dayModel: any) => dayModel.workingHoursModel != null
        );
    
        if (!todayWorkingDay?.workingHoursModel && !hasWorkingHours) {
          return true;
        }
        if (todayWorkingDay.workingHoursModel?.offHours) {
          return false;
        }
    
        const openingHour = moment(todayWorkingDay.workingHoursModel?.openingHour, 'hh:mm A');
        const closingHour = moment(todayWorkingDay.workingHoursModel?.closingHour, 'hh:mm A');
    
        return currentTime.isBetween(openingHour, closingHour);
    }
     
}