import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from './mat-module';
import { GoNextDirective } from '../appDirectives/go-next.directive';
import { NumbersOnlyDirective } from '../appDirectives/numbers-only.directive';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from '../includes/swiper/swiper.module';
import { PromptComponent } from '../includes/popups/prompt/prompt.component';
import { BackPromptComponent } from '../includes/popups/back-prompt/back-prompt.component';
import { VoiceModelComponent } from '../includes/popups/voice-model/voice-model.component';
import { VisualCueComponent } from '../includes/visual-cue/visual-cue.component';
import { CountdownComponent } from '../pages/thanks/countdown/countdown.component';
import { BackComponent } from '../includes/back/back.component';
import { LangComponent } from '../includes/lang/lang.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserInactivityModelComponent } from '../includes/user-inactivity-model/user-inactivity-model.component';
import { RouterModule } from '@angular/router';
import { CustomSnackBarComponent } from '../includes/utilities/custom-snack-bar/custom-snack-bar.component';
import { CardReaderInfoComponent } from '../includes/card-reader-info/card-reader-info.component';

import { LangTranslateModule } from './lang-translate.module';
import {WebcamModule} from 'ngx-webcam';
import { TastioImgComponent } from '../includes/tastio-img/tastio-img.component';
import { OrderTypeDialogComponent } from '../includes/popups/order-type-dialog/order-type-dialog.component';
import { SettingsComponent } from '../includes/popups/settings/settings.component';

@NgModule({
  declarations: [
    GoNextDirective,
    NumbersOnlyDirective,
    UserInactivityModelComponent,
    PromptComponent,
    VisualCueComponent,
    CustomSnackBarComponent,
    BackComponent,
    LangComponent,
    BackPromptComponent,
    TastioImgComponent,
    CountdownComponent,
    VoiceModelComponent,
    OrderTypeDialogComponent,
    SettingsComponent,
    CardReaderInfoComponent
  ],
  imports: [
    CommonModule,
    MatModule,
    SwiperModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    LangTranslateModule,
    RouterModule,
    WebcamModule
  ],
  exports: [
    MatModule,
    GoNextDirective,
    NumbersOnlyDirective,
    SwiperModule,
    HttpClientModule,
    PromptComponent,
    VisualCueComponent,
    OrderTypeDialogComponent,
    SettingsComponent,
    BackComponent,
    LangComponent,
    NgxSkeletonLoaderModule,
    VoiceModelComponent,
    CustomSnackBarComponent,
    RouterModule,
    LangTranslateModule,
    BackPromptComponent,
    TastioImgComponent,
    UserInactivityModelComponent,
    CountdownComponent,
    WebcamModule,
    CardReaderInfoComponent
  ],
  providers: [
  ]
})
export class DesignUtilitiesModule {
}
