import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './appModules/core.module';
import { DesignUtilitiesModule } from './appModules/design-utilities.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenentIdentifierInterceptor } from './auth/tenant-identifier/tenent-identifier.interceptor';
import { NgxStripeModule } from 'ngx-stripe';
import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    DesignUtilitiesModule,
    NgxStripeModule.forRoot()
  ],
  providers: [
    BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: TenentIdentifierInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
