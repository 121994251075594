import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  api = environment.baseUrl + '/payment-gateway';

  constructor(private httpClient: HttpClient) { }

  getAllPaymentGateways(storeId:number, type:string): Observable<any> {
    return this.httpClient.get(`${this.api}/client/${storeId}/${type}`);
  }
}
