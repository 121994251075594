<a mat-icon-button (click)="onNoClick()" class="close">
    <mat-icon>close</mat-icon>
  </a>
  <div class="dialog-wrapper">
    <div class="scroll-wrapper" [ngClass]="{ 
      'show-single-setting-screen': selectedCategory
     }">
  
      <!--***********************************************
        Kioks Types
      -->
      <section class="kiosk-type-wrapper">
        <div class="dialog-header">
          <h2>Kiosk Settings</h2>
        </div>
        <div class="row form-row my-4">
          <span class="col-12 formlbl">Choose category</span>
          <div class="col-12 kiosk-type">
            <mat-card class="card outline-2 mat-elevation-z0" matRipple (click)="selectCategoryType('camera')" [ngClass]="{
                'outline-primary bg-primary-1':selectedCategory === 'camera',
                'background-7':selectedCategory !== 'camera'
              }" *ngIf="enableCameraToggle">
              <figure>
                <mat-icon [ngClass]="{
                  'primary' :selectedCategory === 'camera',
                  'opacity-3' :selectedCategory !== 'camera'
                }">videocam</mat-icon>
              </figure>
              <div class="info">
                <h3 [ngClass]="{
                    'primary':selectedCategory === 'camera'
                }">Camera</h3>
                <span>Setting</span>
              </div>
            </mat-card>
            <mat-card class="card outline-2 mat-elevation-z0" matRipple (click)="selectCategoryType('theme')" [ngClass]="{
                'outline-primary bg-primary-1':selectedCategory === 'theme',
                'background-7':selectedCategory !== 'theme'
              }" *ngIf="enableThemeToggle">
              <figure>
                <mat-icon [ngClass]="{
                  'primary' :selectedCategory === 'theme',
                  'opacity-3' :selectedCategory !== 'theme'
                }">invert_colors</mat-icon>
              </figure>
              <div class="info">
                <h3 [ngClass]="{
                    'primary':selectedCategory === 'theme'
                }">Theme</h3>
                <span>Setting</span>
              </div>
            </mat-card>
            <mat-card class="card outline-2 mat-elevation-z0" matRipple (click)="selectCategoryType('audio')" [ngClass]="{
                'outline-primary bg-primary-1':selectedCategory === 'audio',
                'background-7':selectedCategory !== 'audio'
              }" *ngIf="enableAudioToggle">
              <figure>
                <mat-icon [ngClass]="{
                  'primary' :selectedCategory === 'audio',
                  'opacity-3' :selectedCategory !== 'audio'
                }">volume_up</mat-icon>
              </figure>
              <div class="info">
                <h3 [ngClass]="{
                    'primary':selectedCategory === 'audio'
                }">Audio</h3>
                <span>Setting</span>
              </div>
            </mat-card>
          </div>
        </div>
      </section>
  
  
      <!--***********************************************
        Single Setting Screen
      -->
      <section class="single-setting-screen">
        <div class="dialog-header">
          <button
            mat-icon-button
            class="back-btn opacity-5"
            (click)="onBack()"
          >
            <mat-icon>keyboard_backspace</mat-icon>
          </button>
         <h2 class="mb-0">
            {{selectedCategory | titlecase}}
            <small class="foreground-7 bg-invert-1">
              Settings
            </small>
          </h2>
        </div>
  
        <div class="dialog-content">
          <div class="tabs" *ngIf="selectedCategory==='camera'">
            <button mat-button [ngClass]="{
                'bg-primary text-default':item==selectedTab,
                'bg-app':item!==selectedTab
            }" (click)="setSelectedTab(item)" *ngFor="let item of tabs">
                <mat-icon [color]="(item!==selectedTab)?'primary':''" [class.text-default]="item==selectedTab">{{item.icon}}</mat-icon>
                <span>{{item.value}}</span>
            </button>
          </div>
          <div class="content outline">
            <ng-container *ngIf="selectedCategory==='camera'">
              <div class="permission-popup bg-app-8" *ngIf="camList[0].label.length<1 && selectedTab.value!=='Audio'">
                <h2>Camera permission is blocked</h2>
                <p>
                    App require access to your camera and microphone.
                    Please allow camera permission.
                </p>
              </div>
              <div class="camera-list" *ngIf="selectedTab.value==='Choose Cam'">
                  <ng-container *ngFor="let item of camList">
                      <div class="col cam-card">
                          <div class="bg-app shadow rounded" matRipple 
                          (click)="onChooseCam(item)">
                              <img src="assets/images/cam.svg" alt="">
                              <span class="primary">{{item.label}}</span>
                          </div>
      
                          <mat-radio-group [(ngModel)]="defaultCam" (change)="onDefaultCamChange()">
                              <mat-radio-button [value]="item.label">
                                  <ng-container *ngIf="defaultCam!=item.label">Mark as</ng-container>
                                  Default</mat-radio-button>
                          </mat-radio-group>
                      </div>
                  </ng-container>
              </div>
              <div class="rotate-image" *ngIf="selectedTab.value==='Rotation'">
                          
                  <button mat-icon-button color="primary" (click)="onRotateLeft()">
                      <mat-icon>rotate_left</mat-icon>
                  </button>
      
                  <div class="preview">
                      <webcam [width]="310" [height]="284" class="img" [ngStyle]="{
                          'transform': 'rotate('+degree+'deg)'
                      }"></webcam>
                      <!-- <img class="img" src="assets/images/screen-rotated.jpg" alt="" [ngStyle]="{
                          'transform': 'rotate('+degree+'deg)'
                      }"> -->
                  </div>
                  <span class="degree">{{degree}} Degree</span>
                  
                  <button mat-icon-button color="primary" (click)="onRotateRight()">
                      <mat-icon>rotate_right</mat-icon>
                  </button>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedCategory==='theme'">
              <div class="toggle-settings">
                <div class="row">
                    <div class="col">
                        Toggle Theme
                    </div>
                    <div class="col">
                        <mat-slide-toggle
                            class="example-margin"
                            color="primary"
                            [checked]="darkMode"
                            #theme (toggleChange)="toggleTheme(!theme.checked)"
                            >
                            {{theme.checked ? 'Dark': 'Light'}}
                        </mat-slide-toggle>
                    </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedCategory==='audio'">
               <div class="toggle-settings">
                 <div class="row">
                     <div class="col">
                         Enable Audio
                     </div>
                     <div class="col">
                         <mat-slide-toggle
                             class="example-margin"
                             color="primary"
                             [checked]="enableAudio"
                             #audio (toggleChange)="toggleAudioStatus(!audio.checked)"
                             >
                             {{audio.checked ? 'Enable': 'Disable'}}
                         </mat-slide-toggle>
                     </div>
                 </div>
               </div>
            </ng-container>
          </div>
          <!-- <div class="action-wrapper">
            <button mat-stroked-button color="primary" (click)="saveSettings()">
                <mat-icon>save</mat-icon>
                <span>Save Settings</span>
            </button>
          </div> -->
        </div>
      </section>
  
  
    </div>
  </div>
  
   
  