import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FeatureToggleService } from 'src/app/appFeatures/features';
import { AudioService } from 'src/app/appServices/audio.service';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  tabs = [
    {value: 'Choose Cam', icon:'videocam'},
    {value: 'Rotation', icon: 'screen_rotation'},
  ];
  enableAudio:boolean;
  darkMode:boolean;
  selectedTab=this.tabs[0];

  defaultCam:any;
  degree: number = 0;
  camList:any;
  // camList2 = [
  //   {label: 'cam 1', groupId: 'cam 1'},
  //   {label: 'cam 2', groupId: 'cam 2'}
  // ];

  categories = [
    {value: 'camera', icon:'videocam'},
    {value: 'theme', icon: 'invert_colors'},
    {value: 'audio', icon: 'volume_up'}
  ];
  selectedCategory:any;

  enableCameraToggle:boolean = this._featureToggleService.findFeature('camera');
  enableThemeToggle:boolean = this._featureToggleService.findFeature('theme');
  enableAudioToggle:boolean = this._featureToggleService.findFeature('audio');

  constructor(
    public dialogRef: MatDialogRef<SettingsComponent>,
    private _du:DesignUtilityServices,
    private _featureToggleService: FeatureToggleService,
    private _audioService:AudioService
    ) {
      this.subscribeEnableAudio();
      this.subscribeTheme();
    }
    
  onNoClick(): void {
    this.dialogRef.close();
  }
    
  ngOnInit(): void {
    this.showDevices();
    this.subscribeDefaultCam();
  }

  /**
   * Subscribe Default Camera
   */
  subscribeDefaultCam(){
    this._du.defaultCam.subscribe(res => {
      this.defaultCam = res;
    });
  }


  /**
   * Set Selected Tab
   */
  setSelectedTab(item){
    this.selectedTab = item;
  }

  /**
   * Show Camera Devices
   */
  showDevices() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      // console.log("enumerateDevices() not supported.");
      return;
    }

    // List cameras and microphones.
    navigator.mediaDevices.enumerateDevices().then(devices => {
      let camList = devices.filter((d: any) => d.kind === 'videoinput');
      // let audioinputList = devices.filter((d:any) => d.kind==='audioinput');
      this.camList = camList;
      
      // console.log('camList => ',this.camList)
    })
    .catch(function (err) {
      console.log(err.name + ": " + err.message);
    });
  }

  /**
   * Choose Camera
   */
   onChooseCam(deviceId) {
    // console.log(deviceId)
    this._du.selectedCamID.next(deviceId);
  }
 
  /**
   * Default Camera Change
   */
  onDefaultCamChange() {
    localStorage.setItem('defaultCam', JSON.stringify(this.defaultCam));
    this._du.defaultCam.next(this.defaultCam);
  }


  onRotateLeft() {
    if (this.degree === 0) {
      this.degree = 270;
    } else {
      this.degree = this.degree - 90;
    }
    this._du.degree.next(this.degree);
  }

  onRotateRight() {
    if (this.degree === 270) {
      this.degree = 0;
    } else {
      this.degree = this.degree + 90;
    }
    this._du.degree.next(this.degree);
  }

  saveSettings(){
    this.onNoClick();
  }

  subscribeEnableAudio(){
    this._audioService.enableAudio.subscribe(res=>{
      this.enableAudio = res;
    })
  }


  /**
   * Toggle Audio Status
   * @param active 
   */ 
  toggleAudioStatus(active:boolean){
    this._audioService.enableAudio.next(active);
  }


  /**
   * Subscribe Theme
   */
   subscribeTheme(){
    this._du.theme.subscribe(res=>{
      if(res ==='dark-theme'){
        this.darkMode = true;
      }else{
        this.darkMode = false;
      }
    })
  }
  
  /**
   * Toggle Theme
   * @param theme 
   */ 
  toggleTheme(theme:string){
    // console.log(theme?'dark-theme': 'light-theme');
    this._du.theme.next(theme?'dark-theme': 'light-theme');
  }





  selectCategoryType(type){
    this.selectedCategory= type;
  }

  onBack() {
    this.selectedCategory = null;
  }

}
