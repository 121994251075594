import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MenuServices {

  api = environment.baseUrl;
  constructor(
    private httpClient: HttpClient
  ) { }

  getMenu(menuId: number, client: string): Observable<any> {
    return this.httpClient.get(this.api + "/category/getAll/parentCategory/" +menuId+'/'+client);
  }


  getProductByProductId(productId: number): Observable<any> {
    return this.httpClient.get(this.api + '/product/' + productId).pipe(
      shareReplay()
    );
  }
}
