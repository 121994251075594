import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuServices } from 'src/app/appServices/menu.service';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';

@Injectable({
    providedIn: 'root'
})
export class MenuResolver {
    selectedMenuIndex = new BehaviorSubject(1);
    searchItem = new BehaviorSubject<string>(null);
    store: any;

    clientType: string;

    constructor(
        private _menuService: MenuServices,
        private _storeResolver: StoreResolver,
        private _kioskResolver: KioskResolver
    ) {
        this.subscribeStore();
        this.subscribeKioskDetails();
    }

    /**
     * subscribe kiosk details
     */
    subscribeKioskDetails() {
        this._kioskResolver.kioskDetails.subscribe(res => {
            if (res) {
                this.clientType = res.clientType;
            }
        })
    }

    subscribeStore() {
        this._storeResolver.store.subscribe(res => {
            this.store = res;
        });
    }
    

    

    /**
    * get menu
    */
    getMenu() {
        return this._menuService.getMenu(this._storeResolver.getSelectedStore().menuId, this.clientType);
    }

     
}