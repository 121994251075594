import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KioskService {

  api = environment.baseUrl;
  
  constructor(private httpClient: HttpClient) { }

  /**
   * get kiosk details by code
   * @param kioskCode 
   */
  getKioskDetailsByCode(kioskCode: number): Observable<any> {
    return this.httpClient.get(this.api + '/kiosk/code/' + kioskCode);
  }

  getKioskConfiguration(type): Observable<any> {
    return this.httpClient.get(`${this.api}/kiosk-settings/${type}`);
  }

  /**
   * Get Tenant By Domain 
   * @param domain 
   * @returns 
   */
   getTenantByDomain(domain: string): Observable<any> {
    return this.httpClient.get(`${this.api}/tenant/domain/${domain}`);
  }
  

}

