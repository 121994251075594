import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { KioskService } from '../appServices/kiosk.service';
import { IGlobalConfiguration } from 'src/app/appModels/IGlobalConfiguration';
import { IKiosk } from 'src/app/appModels/IKiosk';
import { DesignUtilityServices } from '../appServices/design-utility.service';


@Injectable({
    providedIn: 'root'
})
export class KioskResolver { 

    kioskDetails = new BehaviorSubject<IKiosk>(null);
    kioskStatus = new BehaviorSubject<boolean>(true);
    duplicateButtons = new BehaviorSubject<boolean>(true);
    
    globalConfiguration = new BehaviorSubject<IGlobalConfiguration>(null);
    
    constructor(
        private kioskService: KioskService,
        private _du: DesignUtilityServices
    ) { }


    /**
     * get kiosk details by code
     * @param code 
     * @returns 
     */
    getKioskDetailsByCode(code: number): Observable<any> {
        return this.kioskService.getKioskDetailsByCode(code);
    }

    getGlobalConfig() {
        this.kioskDetails.subscribe(res=>{
            if(res){
                this.kioskService.getKioskConfiguration(res.clientType).subscribe(res=>{
                    this.globalConfiguration.next(res);
                }, err => {
                    this._du.openCustomSnackBar("kiosk is not configured, Please configured it from admin panel", "Close", "error");
                });

            }
        })
    }



    /**
     * Get Tenant By Domain 
     * @param domain 
     * @returns 
     */
    getTenantByDomain(domain: string) {
        return this.kioskService.getTenantByDomain(domain);
    }

    


}