import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { DesignUtilitiesModule } from './design-utilities.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
    declarations : [
    ],
    imports : [
        CommonModule,
        HttpClientModule,
        DesignUtilitiesModule,
        LoadingBarRouterModule,
        LoadingBarHttpClientModule,
        NgxSkeletonLoaderModule
    ],
    exports : [
        DesignUtilitiesModule,
        LoadingBarRouterModule,
        NgxSkeletonLoaderModule,
    ]
})
export class CoreModule{

}
