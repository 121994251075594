import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { HelperService } from './appServices/helper.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RestaurantBasicInfoStorage } from './appStorage/restaurant-basic-info.storage';
import { MatDialog } from '@angular/material/dialog';
import { BnNgIdleService } from 'bn-ng-idle';
import { UserInactivityModelComponent } from './includes/user-inactivity-model/user-inactivity-model.component';
import { ConnectionService } from 'ng-connection-service';
import { skipInactivityUrls, skipOrderTypeModelUrls } from 'src/app/appConstants/skipInactivityUrls';
import { CardReaderInfoComponent } from './includes/card-reader-info/card-reader-info.component';
import { KioskResolver } from 'src/app/appStorage/kiosk.resolver';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { OrderTypeResolver } from 'src/app/appStorage/order-type.resolver';
import { OverlayContainer } from '@angular/cdk/overlay';
import { LangService } from './appServices/lang.service';
import { KioskSchedularResolver } from './appStorage/kiosk-schedular.resolver';
import { OrderTypeDialogComponent } from './includes/popups/order-type-dialog/order-type-dialog.component';
import { SettingsComponent } from './includes/popups/settings/settings.component';
import { AudioService } from './appServices/audio.service';
import { FeatureToggleService } from './appFeatures/features';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isConnected = true;
  // inactivityTime:number = 60;
  inactivityTime:number = 30000;
  @HostBinding('class') componentCssClass;
  sidenav = false;
  isShow: boolean;
  topPosToStartShowing = 200;
  inactivityModelOpened = false;
  skipInactivityModel: boolean = false;
  enableCameraToggle:boolean = this._featureToggleService.findFeature('camera');
  enableThemeToggle:boolean = this._featureToggleService.findFeature('theme');
  enableAudioToggle:boolean = this._featureToggleService.findFeature('audio');

  constructor(
    public overlayContainer: OverlayContainer,
    private router: Router,
    private _featureToggleService: FeatureToggleService,
    private activatedRoute: ActivatedRoute,
    private _du: DesignUtilityServices,
    private helperService: HelperService,
    private breakpointObserver: BreakpointObserver,
    private restaurantBasicInfo: RestaurantBasicInfoStorage,
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog,
    private connectionService: ConnectionService,
    private kioskResolver: KioskResolver,
    private _kioskSchedularResolver: KioskSchedularResolver,
    private storeResolver: StoreResolver,
    private orderTypeResolver: OrderTypeResolver,
    private _overlayContainer: OverlayContainer,
    private _audioService: AudioService
  ) {

    this._du.theme.subscribe(res => {
      this.componentCssClass = res;
      this._overlayContainer.getContainerElement().classList.add(res);
      if(res==='dark-theme'){
        this._overlayContainer.getContainerElement().classList.replace('light-theme', 'dark-theme');
      }else{
        this._overlayContainer.getContainerElement().classList.replace('dark-theme', 'light-theme');
      }
    });
    this.getViewMode();
    this.checkUrlToSkipInactivityModel();
  }

  // Scroll to Top (Starts)

  ngOnInit(): void {
    this.getConnectionStatus();
    this._kioskSchedularResolver.kioskActiveSchedular();
    //////////////////////////////////////////
    // Meta Title & Description Code Starts //
    //////////////////////////////////////////

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        setTimeout(() => {
          // document.getElementById('wrapper').style.top = null;
          // document.getElementsByTagName('html')[0].classList.remove('hideScroll');
          window.scrollTo(0, 0);
          // this._du.menuToggler.next(false);
        }, 200);
      });

    //////////////////////////////////////////
    //  Meta Title & Description Code Ends  //
    //////////////////////////////////////////
    this.appItialization();
  }

  @HostListener('window:scroll')
  checkScroll(): void {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  // Scroll to Top (ends)



  /**
   * View Mode of Screen
   */
  getViewMode() {
    this.breakpointObserver.observe([
      '(orientation: portrait)'
    ]).subscribe(result => {
      this.helperService.portraitView.next(result.matches);
    });
  }


  /**
 * intialize app
 * call required services
 */
  appItialization(): void {
    this.checkUserInactivity();
    this.openCardReaderInfoDialog();
    this.validateKiosk();
    this.checkUrlToSkipOrderTypeModel();
    // this.subscirbeUseCamera();
    this.configureCameraSettings();
    this.openSettingsDialog(); 
  }

  /**
   * check url to skip order type model
   */
   checkUrlToSkipOrderTypeModel() {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (!skipOrderTypeModelUrls.includes(this.router.url)) {
            this.subscribeSelectedOrderType();
          }
        }
      }
    );
  }

  /**
   * Subscribe Selected Order Type
  */
   subscribeSelectedOrderType() {
    this.orderTypeResolver.selectedOrderType.subscribe(res => {
      if (!res) {
        this.openOrderTypeDialog();
      }
    })
  }

  /**
   * Order Type Dialog
  */
   openOrderTypeDialog() {
    this.dialog.open(OrderTypeDialogComponent, {
      width: '80%',
      maxWidth: '50rem',
      panelClass: ['bg-app'],
      disableClose: true
    });
  }


  // API calls
  /**
 * get Basic Info
 */
  getBasicInfo() {
    this.restaurantBasicInfo.getBasicInfo();
  }

   


  
  checkUrlToSkipInactivityModel() {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (skipInactivityUrls.includes(this.router.url)) {
            this.skipInactivityModel = true;
          } else {
            this.skipInactivityModel = false;
          }
        }
      }
    );
  }
  checkUserInactivity() {
    this.bnIdle.startWatching(this.inactivityTime).subscribe((isTimedOut: boolean) => {
      if (isTimedOut && !this.inactivityModelOpened && !this.skipInactivityModel && this.isConnected) {
        this.inactivityModelOpened = true;
        this.openInactivityModel();
      }
    });
  }
  openInactivityModel(): void {
    this._audioService.playAudio('stillLooking');
    const dialogRef = this.dialog.open(UserInactivityModelComponent, {
      maxWidth: '90%',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.inactivityModelOpened = false;
    });
  }

  // Internet Connection Code (Starts)

  getConnectionStatus() {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this._du.internetStatus.next('ONLINE');
      }
      else {
        this._du.internetStatus.next('OFFLINE');
      }
    })
  }

  // Internet Connection Code (Ends)


  /**
 * open card reader info dialog
 */
  openCardReaderInfoDialog(): void {
    const crnumber = localStorage.getItem('card-reader-number');
    if (!crnumber) {
      this.dialog.open(CardReaderInfoComponent);
    }

  }

  /**
   * validate kiosk
   * @returns 
   */
  validateKiosk() {
    if (!localStorage.getItem('tenant-name')) {
      this.router.navigate(['/kiosk-code']);
      return;
    } else {
      this.restaurantBasicInfo.getBasicInfo();
    }
    this.kioskResolver.getGlobalConfig();
    const kioskCode = localStorage.getItem('kiosk-code');
    if (!kioskCode) {
      this.router.navigate(['/kiosk-code']);
    } else {
      this.getKioskDetails(Number.parseInt(kioskCode));
    }
  }

  /**
   * get kiosk details
   * @param kioskCode 
   */
  getKioskDetails(kioskCode: number) {
    this.kioskResolver.getKioskDetailsByCode(kioskCode).subscribe(res => {
      this.kioskResolver.kioskDetails.next(res);
      this.kioskResolver.duplicateButtons.next(res.duplicateButtons);
      this._du.theme.next((res.lightMode?'light-theme': 'dark-theme'));
      this.storeResolver.getStore(res.storeId);
      this.getOrderTypes(res.storeId);
    }, err => {
      this._du.openCustomSnackBar("Kiosk Code is Invalid", "Close", "error");
      this.router.navigate(['/kiosk-code']);
    });
  }

  // API calls
  getOrderTypes(storeId: number) {
    this.storeResolver.store.subscribe(res => {
      res && this.orderTypeResolver.getOrderTypes(res.id);
    });
  }


  /**
   * Subscribe Use Camera
   */
  
  subscirbeUseCamera() {
    this.kioskResolver.globalConfiguration.subscribe(res => {
      if (res?.camera) { 
        this.openSettingsDialog(); 
      }
    });
  }

  /**
   * Open Settings
  */
  openSettingsDialog() {
    if(this.enableCameraToggle || this.enableThemeToggle || this.enableAudioToggle){
      this.dialog.open(SettingsComponent, {
        width: '80%',
        maxWidth: '80rem',
        disableClose: true
      });
    }
  }

  configureCameraSettings(){
    const defaultCam = JSON.parse(localStorage.getItem('defaultCam'));
    if (defaultCam) {
      this._du.defaultCam.next(defaultCam);
    }
  }



}
